import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/BlogLayout/index.jsx";
import BlogPostMetaData from "../../../../components/BlogPostMetaData";
import TCCOutboundLink from "../../../../components/TCCOutboundLink";
export const pageMetaData = {
  pageTitle: "Everything You Need To Know About Electricity",
  pageStrapLine: "Things to consider when shopping for a new Electricity contract",
  pageImagePath: "/2021-03-16-electricity-facts.jpg",
  pageDescription: "We give you all of the facts you need to make an informed choice when signing up for a new Electricity contract.",
  pageMetaTags: []
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Everything You Need To Know About Electricity`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1a7f753f6ff655de196a462e758e5b05/80e3c/2021-03-16-electricity-facts.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHSlTygwP/EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMRBBIh/9oACAEBAAEFAlfFHjaObFRLRf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAIDAAAAAAAAAAAAAAAAAQIQABEh/9oACAEBAAY/ArVnJzruv//EABgQAAMBAQAAAAAAAAAAAAAAAAABERBR/9oACAEBAAE/IaKRRVFeinvBn//aAAwDAQACAAMAAAAQCM//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QiP/EABwQAQADAAIDAAAAAAAAAAAAAAEAESFhgTFBUf/aAAgBAQABPxAIQMb2I+xTDcBeOeYgHeWUHUd09ZP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Everything You Need To Know About Electricity",
            "title": "Everything You Need To Know About Electricity",
            "src": "/static/1a7f753f6ff655de196a462e758e5b05/80e3c/2021-03-16-electricity-facts.jpg",
            "srcSet": ["/static/1a7f753f6ff655de196a462e758e5b05/f93b5/2021-03-16-electricity-facts.jpg 300w", "/static/1a7f753f6ff655de196a462e758e5b05/b4294/2021-03-16-electricity-facts.jpg 600w", "/static/1a7f753f6ff655de196a462e758e5b05/80e3c/2021-03-16-electricity-facts.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <BlogPostMetaData postDate={props.pageContext.frontmatter.date} category={props.pageContext.frontmatter.blogCategory} mdxType="BlogPostMetaData" />
    <p>{`Electricity, we all use it, it's an essential modern-day convenience, but what do we know about it? For example, did you know that the average U.S household consumes 11,000-kilowatt hours of electricity per year? Or that there are three major categories of electricity generation within energy?`}</p>
    <p>{`You may have never thought about the amount of energy you're using or much past making sure the lights stay on. But knowing what your electricity is, where your electricity is coming from, and what it is going towards, can help save money, make informed decisions about household energy use, and even aid in overall conservation.`}</p>
    <p>{`Below are 10 energy facts to keep in mind when choosing your next provider.`}</p>
    <h3>{`1`}{`.`}{` What Is The Average Household Electricity Usage?`}</h3>
    <p>{`As stated above, the average US household does use around 11,000-kilowatt hours a year. However, this use varies depending on housing type across different regions in the U.S. For example, smaller units in the Northeast consume less energy than those in a single-family home in the South, due to higher usage of air conditioning and heat.`}</p>
    <h3>{`2`}{`.`}{` How Fast Does Electricity Travel?`}</h3>
    <p>{`Electricity almost travels at the speed of light, close to 186,000 miles per second. However, electricity flowing through wires to buildings and applications travel at a much slower rate, about 1/100 of its potential speed. Partly due to the electrons flowing through the wires constantly bumping into the atoms of the wire itself, lagging it considerably.`}</p>
    <h3>{`3`}{`.`}{` How Is Electricity Generated?`}</h3>
    <p>{`There are many different energy sources and technologies to generate energy, however, the three major categories of energy use within electricity generation fall under fossil fuels (coal, natural gas, and petroleum), nuclear energy, and renewable energy sources; with some methods being used more than others depending on the region.`}</p>
    <h3>{`4`}{`.`}{` How Is Electricity Distributed?`}</h3>
    <p>{`Electricity travels quite a ways to power your home, office, and life, it travels from generation facilities to a high-voltage switchyard, then to transmission lines, bouncing over to a substation, hopping on the distribution line, being shot to a transformer, and finally filtering into your house.`}</p>
    <h3>{`5`}{`.`}{` How Do Birds Sit On Power Lines Without Being Electrocuted?`}</h3>
    <p>{`Birds, squirrels, and other small animals can perch and lightly run along the power lines without being electrocuted. Not unless they simultaneously touch another line, which would create a complete current, additionally small animals don't make great conductors of electricity.`}</p>
    <h3>{`6`}{`.`}{` How To See A Saving By Switching Your Bulbs.`}</h3>
    <p>{`Saving energy in your household or business can be as simple as switching your light bulbs. The older incandescent light bulbs take 25%-80% more energy to power than the newer LED versions, which can save hours on your kilowatts every year.`}</p>
    <h3>{`7`}{`.`}{` What Is Renewable Electricity?`}</h3>
    <p>{`Renewable energy comes from sources such as wind, solar, or geothermal. Geothermal energy comes from the Earth, consisting of hot water and hot rock that is only a few miles beneath Earth's crust. In the U.S, areas that mostly use this method of energy are Nevada, Oregon, Arizona, Utah, Idaho, New Mexico, and Colorado, and is considered the cleanest method of renewable energy.`}</p>
    <h3>{`8`}{`.`}{` What Are Fossil Fuels?`}</h3>
    <p>{`Though we have many renewable options available to us, we still dominantly use nonrenewable energy centered around fossil fuels, something we only have a finite amount of, and is formed from the buried remains of plants and animals that lived millions of years ago, unable to be replenished like renewable energy sources.`}</p>
    <h3>{`9`}{`.`}{` What Do We Mainly Use Electricity For?`}</h3>
    <p>{`An average of 40 percent of energy from all resources, both renewable and non, is used for electricity to power our everyday lives, which means that our resources are used more for our convenience than any other purpose.`}</p>
    <h3>{`10`}{`.`}{` How Do I Save Money On My Electricity Usage?`}</h3>
    <p>{`Unplug your electronics when you aren't using them, this alone will save you on Kilowatts every year by releasing ‘reserved energy’ that gets filtered into products that are plugged in but not being used.`}</p>
    <h3>{`Wrapping Up`}</h3>
    <p>{`The more you know about what you're using, the better decisions you can make to help choose the right money-saving plan for you and your family.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://thecomparison.company"
      }}>{`The Comparison Company`}</a>{` for more money-saving advice, as well as helping you find the best energy source for you and your family.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      